import React from "react";

export const Banner = (props: any) => {
  return (
    <div
      className="flex relative min-h-[800px] bg-cover flex flex-col justify-center"
      style={{ backgroundImage: 'url("/assets/Banner Final_No_court-01.jpg")' }}
    >
      {/* Banner Txt */}
      <div className="uppercase z-10 flex flex-col h-[100%] justify-center px-[5%] pb-10 text-primary z-10 tracking-[20px] break-keep">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <h1 className="text-[24px] sm:text-[64px] font-[800]">MAIN</h1>
          <h1 className="text-[24px] w-fit sm:w-[auto] sm:text-[64px] bg-primary p-2 pl-4 ml-0 sm:ml-5 text-secondary font-[800]">
            Sequence
          </h1>
        </div>
        <h1 className="text-[24px] sm:text-[64px] mt-0 sm:mt-10 font-[800]">
          Sports
        </h1>

        <p className="text-white capitalize mt-10 text-[16px] tracking-[2px]">
          Sports analytics for all.
        </p>
      </div>

      {/* Court */}
      <div className="absolute right-[40px] top-[20%] w-[30%] min-w-[450px] desktop">
        <img
          src="/assets/Banner_court_21-FEB-2023.png"
          alt="court"
          className="w-full h-full object-contain"
        />
      </div>

      {/* Opacity */}
      {/* <div className="w-[100%] h-[100%] bg-black/70 absolute top-0 left-0" /> */}
    </div>
  );
};
