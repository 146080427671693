import React from "react";
export const Footer = (props: any) => {
  return (
    <div className="flex flex-col relative bottom-0 container mx-auto px-10 ">
      <p className="text-[10px]">
        Copyright @ 2023 Main Sequence Sports. All rights reserved.
      </p>
      <div className="pt-5 flex flex-row">
        <img
          src="/assets/instagram.png"
          alt="ig"
          className="w-[30px] h-[30px] cursor-pointer"
          onClick={() =>
            window.open(
              "https://www.instagram.com/mainsequencesports/",
              "_blank"
            )
          }
        />
        <img
          src="/assets/linkedin.png"
          alt="linkedin"
          className="ml-5 w-[30px] h-[30px] cursor-pointer"
          onClick={() =>
            window.open(
              "https://www.linkedin.com/company/main-sequence-sports/",
              "_blank"
            )
          }
        />
      </div>
    </div>
  );
};
