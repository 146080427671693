import React from "react";
import { Container, Footer } from "../../components";
import { Banner } from "./banner";
import { AboutUs } from "./about-us";
import { Missions } from "./missions";
import { OurProducts } from "./our-products";

export const Home = (props: any) => {
  return (
    <Container noTopPadding={true}>
      <Banner />
      <div id="home-bg" className="pb-10">
        <div className=" mx-auto py-10 sm:py-20 ">
          {/* Why Us Section */}
          <div>
            <AboutUs />
          </div>
          {/* Mission Section */}
          <div className="pt-10 sm:pt-20">
            <Missions />
          </div>
          {/* Our Product Section */}
          <div className="pt-10 sm:pt-20">
            <OurProducts />
          </div>
          {/* Footer */}
          <div className="pt-10 sm:pt-20">
            <Footer />
          </div>
        </div>
      </div>
    </Container>
  );
};
