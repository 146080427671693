import React from "react";

export const AboutUs = (props: any) => {
  return (
    <div className="text-center" id="about-us">
      <div className="max-w-[900px] mx-auto px-10">
        <h2>About Us</h2>
        <p className="subtitle pt-10 pb-10">
          Welcome to Main Sequence Sports Analytics!
        </p>
        <p>
          We know the exhilaration of victory, the frustration of defeat, and
          the burning desire to improve. With our data-analytics platform you
          can learn from mistakes, replicate successful strategies, and enhance
          performance. Gone are the days of relying on memory or handwritten
          notes; technology has revolutionized the game.
        </p>
        <p className="pt-10">
          We believe that data analysis should be accessible to all, not just
          elite clubs. Main Sequence Sports Analytics levels the playing field,
          offering simple, affordable, and user-friendly tools. Our platform
          empowers semi-professional and community clubs to achieve consistent
          victories through data analytics.
        </p>
        <p className="pt-10">
          Whether you're aiming for the top spot or seeking improvement at the
          local level, we've got you covered. Get ready to unleash your team’s
          potential. Let's make it happen!
        </p>
      </div>
    </div>
  );
};
