import React from "react";

const STRENGTHS = [
  {
    title: "Mission",
    desc: "Our mission is to make sports analytics accessible and affordable for clubs of all levels.",
    icon: "/assets/mission_v2.jpg",
  },
  {
    title: "Vision",
    desc: "We believe every club that wants to improve should have affordable and accessible solutions to technological innovation.",
    icon: "/assets/vision_v2.jpg",
  },
  {
    title: "Objective",
    desc: "Improve the overall quality of the game by supporting clubs of all levels to access technology in a friendly way. As a company, we strive to reduce as many barriers as possible for clubs to adopt technological advancements.",
    icon: "/assets/objective_v2.jpg",
  },
];

export const Missions = (props: any) => {
  return (
    <div
      className="text-center py-20 bg-cover bg-fixed relative"
      id="missions"
      style={{ backgroundImage: 'url("/assets/Mission Background.jpg")' }}
    >
      <div className="container mx-auto px-10">
        {/* <h2 className="relative z-10">Our Missions</h2> */}
        {/* Advantages Block */}
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-10 z-10 relative">
          {STRENGTHS.map((item, i: number) => {
            return (
              <div
                key={i}
                className="flex flex-col items-start mx-auto w-[100%] text-left bg-white"
              >
                <p className="bg-primary text-black py-3 px-5 w-[100%] text-[14px]">
                  {item.title}
                </p>
                <div className="w-[100%]">
                  <img
                    src={item.icon}
                    alt={item.title}
                    className="w-full h-full object-fit"
                  />
                </div>
                <p className="text-black py-5 px-5 text-[12px]">{item.desc}</p>
              </div>
            );
          })}
        </div>
      </div>

      {/* Opacity */}
      {/* <div className="w-[100%] h-[100%] bg-black/70 absolute top-0 left-0 z-0" /> */}
    </div>
  );
};
