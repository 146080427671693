import React from "react";

const PRODUCTS = [
  {
    type: "Coaching Staff",
    desc: `One of the main challenges as a coach is to implement a playing model effectively. Throughout the season coaches design and run sessions to develop habits, skills, and movements as part of their strategy. Data analytics plays an essential role in this process, as it measures how well the players are executing the model. At Main Sequence, we build that tool for you. To help you understand your team's performance and to help you gain that competitive edge..
    `,
    image: "/assets/staff.jpg",
  },
  {
    type: "Players",
    desc: `Take your game to the next level, and learn more about yourself, your playing style, and your strengths and weaknesses. We will help you to develop your best football, by combining team tactical analysis and your specific data. We can help you to improve your skills, adapt your game to the coach’s requirements, or to showcase your talent to others. We are here to understand your goals and to help you achieve them using data analysis.`,
    image: "/assets/players.jpg",
  },
];

export const OurProducts = (props: any) => {
  return (
    <div
      className="text-left pb-20 bg-cover bg-fixed relative"
      id="our-products"
    >
      <div className="container mx-auto px-10 flex items-start flex-col sm:flex-row">
        <div className="flex flex-col flex-1">
          <h2>
            Our
            <br />
            Products
          </h2>
          <p className="mt-10">
            We offer comprehensive solutions that bundle video data mining, data
            processing, and data analysis to create specific reports for your
            needs.
          </p>
          <button
            className="primary mt-10 max-w-[250px]"
            onClick={() =>
              window.open("https://calendly.com/mainsequencesports", "_blank")
            }
          >
            Book Appointment
          </button>
        </div>

        {/* Products */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 pl-0 sm:pl-40 mt-10 sm:mt-0">
          {PRODUCTS.map((item, i) => {
            return (
              <div
                key={i}
                className="relative bg-white w-[100%] sm:w-[350px] h-fit"
              >
                <div className="max-w-[350px] relative">
                  <img
                    src={item.image}
                    alt={item.type}
                    className="w-full h-full object-contain"
                  />
                  <p className="bg-primary text-black py-3 px-5 text-[14px] absolute left-0 bottom-0">
                    {item.type}
                  </p>
                </div>
                <pre className="text-black py-5 px-5 pb-5 text-[12px]">
                  {item.desc}
                </pre>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
